.home-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home-container .profile__img {
	height: 300px;
	width: 300px;
	border-radius: 50%;
	border: 3px solid rgb(108, 161, 197);
	animation: fade-in-from-top 1.8s;
}

.skills {
	display: flex;
}


.skills span img {
	height: auto;
	width: 100%;
	transition: all ease-in-out 0.5s;
	animation: fade-in-from-left 1.8s;	
}

.skills span img:hover {
	transform: scale(1.2);
}
