.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 80px;
}

.contact-container h2 {
  margin: 0 auto;
  font-size: 28px;
  font-family: 'Open Sans Condensed';
  color: var(--color-pri);
}

.contact-container .contact-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.contact-container .contact {
  height: 280px;
  width: 30%;
  background-color: rgba(0, 0, 0, 0.322);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, transform 0.2s ease;
}

.contact-container .contact:hover {
  background-color: rgba(255, 255, 255, 0.096);
  transform: scale(1.1);
}

.contact-container .contact .icon {
  font-size: 130px;
}

.contact-container .contact h2 {
  margin-top: 15px;
  font-size: 21px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  text-align: center;
}

.contact-container .contact h2 span {
  font-size: 16px;
  font-weight: 100;
  margin-top: 3px;
  color: rgb(202, 202, 202);
}

@media (max-width: 820px) {

  .contact-container h2 {
    font-size: 40px;
  }

  .contact-container .contact-links {
    flex-direction: column;
    justify-content: space-around;
    margin-top: 30px;
  }

  .contact-container .contact {
    height: 400px;
    width: 80%;
    margin: 25px;
  }

  .contact-container .contact .icon {
    font-size: 200px;
  }

  .contact-container .contact h2 {
    font-size: 30px;
  }

  .contact-container .contact h2 span {
    font-size: 25px;
  }
}