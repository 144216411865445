

.header-container {
  text-align: center;
  margin-top: 18px;
  animation: fade-in-from-top 1s;
}

.header-container h3 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  font-family: Poppins;
}

.header-container p {
  text-transform: uppercase;
  font-size: 20px;
}

.header-container p span {
  color: var(--color-pri);
}

.header-container h2 {
  font-size: 16px;
  font-weight: 100;
  color: var(--color-pri);
  font-family: "Open Sans";
  letter-spacing: 1px;
}
