@keyframes porfolioBgImage {
    from {background-position: center 0%;}
    to {background-position: center 100%;}
}

@keyframes fadeInOpacity {
    from {opacity: 0;}
    to {opacity: 0.8;}
}



.portfolio-container__inner{
    width: 100%;
    display: grid;
    text-align: center;
    gap: 2rem;
}

@media only screen and (min-width: 600px) {
    .portfolio-container__inner{
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

.portfolio__item{
    height:400px;
    overflow: hidden;
    background-position: center 0%;
    background-size: cover;
    display: flex;
    cursor: pointer;    
}

.portfolio__item:hover{
    animation-name: porfolioBgImage;
    animation-duration: 8s;
}

.portfolio__item_name{
    display: none;
    font-weight: bold;
    color: black;
    background-color: aliceblue;
    padding: 8px;
    width: 75%;
    height: 50%;
    margin: auto;
    opacity: 0;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.modal_overlay{
    width:100vw;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
}

.portfolio__item_content{
    color: black;
    position: fixed;
    width:75%;
    height:75%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 2;
}

.portfolio__item_content__inner{
    padding:28px;
    position: relative;
}
.portfolio__item_content h2, .portfolio__item_content p{
    color: black;
}
 
.portfolio__item_content span{
    color: #fff;
    width:30px;
    height: 30px;
    border-radius: 50%;
    font-weight: bold;
    background-color: #000;
    padding:8px;
    cursor: pointer;
    position: absolute;
    right:18px;
    top:18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.2s;
}

.portfolio__item_content span:hover{
    background-color: #666;
    transform: rotate(360deg);
}

.portfolio__item:hover .portfolio__item_name{
    display: flex;
    opacity: 0.8;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}