@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	color: white;
}
::-webkit-scrollbar {
	width: 0;
}

:root {
	--color-pri: rgb(82, 188, 250);
	--color-pri-light: rgba(82, 188, 250, 0.445);
	--color-sec: rgb(172, 17, 233);
	--color-sec-light: rgba(172, 17, 233, 0.274);

	--pc-width: 75%;
	--tab-width: 90%;
	--phone-width: 100%;
}

#root {
	position: relative;
}
html {
	scroll-behavior: smooth;
}

body {
	background: rgb(2, 2, 26);
	font-family: "Inter", sans-serif;
	animation: move 1s linear infinite forwards;
}

body::before {
	content: "";
	position: fixed;
	height: 200px;
	width: 200px;
	background: rgb(0, 255, 255);
	filter: blur(140px);
}
body::after {
	content: "";
	position: fixed;
	top: 200px;
	left: 250px;
	height: 200px;
	width: 200px;
	background: rgb(183, 0, 255);
	filter: blur(140px);
}

p, h2{
  padding:8px 0 12px 0;
}

.container {
	width: 95%;
	color: var(--text-pri);
	padding: 10px 40px;
	margin:auto;
  text-align: center;
}

@media (min-width: 960px) {
  .container {
    width:85%;
    max-width: 1920px;
  }
}


@keyframes fade-in-from-top {
  from {
    transform: translateY(-200px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in-from-left {
  from {
    transform: translateX(-2000px);
    opacity: 0;
  }
  to{
    transform: translateX(0px);
    opacity: 1;
  }
}