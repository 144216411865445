.button-container {
  display: flex;
  margin-top: 30px;
  padding: 0;
  justify-content: center;
}

.button-container .btn {
  height: 80px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--text-pri);
  transition: 0.2s ease;
  overflow: hidden;
}

.button-container a {
  font-size: 22px;
}

.button-container .btn.pri {
  border: 2px solid var(--color-sec);
}

.button-container .btn.pri:hover {
  border: none;
  background-color: var(--color-sec);
}